@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-sans bg-primary;
}

.site-main-px {
  @apply px-6 lg:px-8;
}

.block-content {
  @apply text-base md:text-lg;
}

.floating-main-px {
  @apply px-6 md:px-8;
}

.floating-main-py {
  @apply py-6;
}

.paragraph p,
ul {
  @apply block-content mb-[1em] last:mb-0 list-disc list-inside;
}

.p-margin p {
  @apply mb-[1em] last:mb-0;
}

.search-input {
  @apply bg-transparent border-b-2 border-b-secondary py-1 text-lg outline-none focus:border-b-primary placeholder:font-normal focus:font-medium transition-colors;
}

.collegues-gap {
  @apply gap-8 sm:gap-10 md:gap-28 lg:gap-32 xl:gap-32 2xl:gap-36;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track-piece {
  background-color: #4bc0b5;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
